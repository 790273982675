<template>
  <div class="row">
    <b-dropdown  variant="primary" text="+ Yeni Ekle" class="ml-auto">
      <b-dropdown-item :to="{ name: 'musteri-ekle' }">
             Müşteri Ekle
           

      </b-dropdown-item>
      <b-dropdown-item>Kiracı Ekle</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'mulk-ekle' }">Mülk Ekle</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'talep-ekle' }">Talep Ekle</b-dropdown-item>

    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/core/services/store/auth.module';

export default {
  name: 'welcome',
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    getUserRoleText() {
      if (!this.currentUser || !this.currentUser.f) return;

      return this.$customFunctions.getUserTypeText(this.currentUser.t);
    },
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
    },
  },
};
</script>
